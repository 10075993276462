import React, { Component, Fragment } from 'react';
import CampoFormatado from '../components/campo-formatado';
import FormulariosService from '../services/formularios.service';
import EnderecosService from '../services/enderecos.service';
import TextUtil from '../utils/text'

class CamposInput extends Component {
  constructor(props = { valores: {}, flPermiteEditar: true }) {
    super(props);

    let valores = props.valores || {};
    let state = { valores, timeoutAtualizacao: undefined };

    for (let campo of props.campos) {
      state.valores[campo.id] = valores[campo.id] || '';
    }
    this.state = state

    this.verificarCamposHabilitados();
  }

  handleCampoAlterado = (campo, valor) => {
    let valores = this.state.valores
    valores[campo.id] = valor
    this.setState({ valores }, async () => {
      this.verificarCamposHabilitados();

      if (this.props.onDadosAlterados)
        await this.props.onDadosAlterados(this.state.valores);

      if (this.props.onEvento) {
        if (campo.cdEvento && campo.selecionarAutomaticamente)
          this.props.onEvento(campo.cdEvento)
        else if (campo.cdEvento && campo.atualizarAutomaticamente) {
          if (this.state.timeoutAtualizacao) clearTimeout(this.state.timeoutAtualizacao);
          let timeout = setTimeout(() => this.props.onEvento(campo.cdEvento), campo.delayAtualizacao || 500);
          this.setState({ timeoutAtualizacao: timeout })
        }
      }

    })
  }

  verificarCamposHabilitados = () => {
    for (let c of (this.props.campos || [])) {
      if (c.id === 'cdProprietario') {
        if (this.state.valores.cdTipoProprietario === 'PESSOA_FISICA') {
          c.label = 'CPF do proprietário (conforme documento)';
          c.mascara = '999.999.999-99';
          c.tipo = 'cpf';
        } else {
          c.label = 'CNPJ do proprietário (conforme documento)';
          c.mascara = '99.999.999/9999-99';
          c.tipo = 'cnpj';
        }
      }
    }
  }

  handleOnBlur = async (campo, valor) => {
    if (campo.tipo == 'cep') {
      this.handleOnBlurCep(valor);
    } else {
      if (campo.cdEvento && campo.atualizarAutomaticamente) {
        if (this.state.timeoutAtualizacao) clearTimeout(this.state.timeoutAtualizacao);
        this.props.onEvento(campo.cdEvento);
      }
    }
  }

  handleOnBlurCep = async (valor) => {
    this.setState({ loader: { message: 'processando...', show: true }, error: null });

    let cdCepLimpo = TextUtil.retornarNumerosSemEspaco(valor);
    if (cdCepLimpo.length !== 8) {
      return this.state.msg = 'Cep incompleto, verificar por favor!';
    }
    let cep = await EnderecosService.recuperarCep(cdCepLimpo);
    let valores = this.state.valores

    valores.cdUf = cep.cdUf;
    valores.dsCidade = cep.dsCidade ? cep.dsCidade.toUpperCase() : (valores.dsCidade || '');
    valores.dsLogradouro = cep.dsLogradouro ? cep.dsLogradouro.toUpperCase() : (valores.dsLogradouro || '');
    valores.dsBairro = cep.dsBairro ? cep.dsBairro.toUpperCase() : (valores.dsBairro || '');

    this.setState({
      valores,
      loader: {}
    });
  }

  render() {
    return <div className="row justify-content-center m-0" >
      {!this.props.campos ? null : <Fragment>
        {(this.props.campos || []).filter(c => {
          return FormulariosService.avaliarPreRequisito(c, this.state.valores)
        }).map((campo, index) => <CampoFormatado
          key={index}
          id={campo.id}
          className="col-12 p-0"
          valor={this.state.valores[campo.id]}
          label={campo.label}
          placeholder={campo.placeholder}
          formatacao={campo.formatacao}
          mascara={campo.mascara}
          tamanho={campo.tamanho}
          tipo={campo.tipo}
          opcoes={campo.opcoes}
          texto={'Autorizo que meus dados de contato sejam enviados ao vendedor para que ele entre em contato comigo'}
          obrigatorio={campo.obrigatorio}
          desabilitado={!this.props.flPermiteEditar || !campo.habilitado}
          onChange={(valor) => { this.handleCampoAlterado(campo, valor) }}
          onBlurOk={(valor) => { this.handleOnBlur(campo, valor) }}
          onFocus={campo.selecionarConteudoAoFocar ? (event) => event.target.select() : undefined}
          style={Object.assign({ borderRadius: '10px', color: '#666666', height: '50px', border: '1px solid #666666', fontSize: '17px' }, campo.style || {})}
        />
        )}

      </Fragment>
      }
    </div>
  }
}

export default CamposInput;
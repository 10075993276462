import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import LoaderCircular from '../components/loader/loader-circular'
import moment from 'moment';

import Utils from '../utils/text';
import FormulariosService from '../services/formularios.service';
import CampoMoeda from './campo-moeda';
import analyticsService from '../services/analytics.service';

let DATAS_VENCIMENTO = [];
let dataControle = moment.utc();
dataControle.add('days', 15);
for (let x = 0; x < 20; x++) {
  if (dataControle.get('weekday') !== '0' && dataControle.get('weekday') !== '6') {
    DATAS_VENCIMENTO.push(dataControle.format('DD/MM/YYYY'));
  }
  dataControle.add('days', 1)
}

class CampoFormatado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valor: props.valor || '',
      loader: false,
      lastUpdate: new Date().getTime(),
      err: ''
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Correção de BUG
    // Adicionado tempo da última atualização, pois sem esta validação, toda informação enviada do campo
    // para o componente mais externo, estava causando uma RE-atualização dos dados, causando conflito com
    // os teclados dos smartphones.
    if (new Date().getTime() - state.lastUpdate < 200 || props.valor === state.valor) return null;
    return { valor: props.valor || state.valor };
  }

  componentDidMount() {
    if (this.state.valor) {
      let result = FormulariosService.avaliarConteudoCampo(this.state.valor, this.props.tipo, this.props.obrigatorio, this.props.minimo, this.props.maximo);

      if (!result.isValid) {
        this.setState({ err: result.err });
        return;
      }
    }
  }



  handleCheckboxChange = (event) => {
    const target = event.target;
    let value = target.checked || false;
    this.setState({
      valor: value,
      err: null
    });

    if (this.props.onChange)
      this.props.onChange(value);
  }

  handleInputChange = (event) => {
    let value
    const target = event.target;
    if (event.target.type == 'email') {
      value = target.value || '';
    } else {
      value = target.value.toUpperCase() || '';
    }
    value = this.aplicarFormatacao(value);

    this.setState({
      valor: value,
      lastUpdate: new Date().getTime(),
      err: null
    }, async () => {
      if (this.props.onChange)
        this.props.onChange(value);

      if (this.props.tipo === 'cep' && value.length === 9 && this.props.onDadosEnderecoRecuperados) {
        this.setState({ loader: true });
        try {
          let dadosEndereco = await FormulariosService.recuperarCep(value);
          this.props.onDadosEnderecoRecuperados(dadosEndereco);
        } catch (error) {
          console.error(error);
          analyticsService.sendException(error, false);
        }
        this.setState({ loader: false });
      }
    });
  }

  handleOpcaoSelecionada = (event) => {
    const target = event.target;
    let value = target.value || '';
    this.setState({
      valor: value,
      err: null
    });

    if (this.props.onChange)
      this.props.onChange(value);
  }

  aplicarFormatacao = (value) => {
    if (this.props.formatacao !== 'mascara') {
      if (this.props.tamanho) {
        value = value.substr(0, this.props.tamanho);
      }

      if (this.props.maximo) {
        if (parseInt(value.replace(/[^\d]+/g, "")) > this.props.maximo) value = String(this.props.maximo);
      }
    }

    if (this.props.formatacao) {
      switch (this.props.formatacao) {
        case 'moeda':
          value = Utils.formatarMoedaV2('R$ ', value);
          break;
        case 'digitos':
          value = value.replace(/[^\d]+/g, "");
          break;
        default:
          break;
      }
    }

    if (this.props.formatacao === 'mascara')
      value = value.replace(new RegExp('_', 'gi'), '');

    return value;
  }

  handleBlur = () => {

    if (this.props.formatacao !== 'mascara') {
      if (this.props.minimo) {
        if (!this.state.valor || parseInt(this.state.valor.replace(/[^\d]+/g, "")) < this.props.minimo) {
          let valor = String(this.props.minimo);
          valor = this.aplicarFormatacao(valor);
          this.setState({ valor, err: 'O valor foi alterado para o mínimo possível.' });

          if (this.props.onChange)
            this.props.onChange(valor);

          return;
        }
      }
    }

    let result = FormulariosService.avaliarConteudoCampo(this.state.valor, this.props.tipo, this.props.obrigatorio, this.props.minimo, this.props.maximo);
    if (!result.isValid) {
      this.setState({ err: result.err });
      return;
    }

    if (this.props.onBlurOk)
      this.props.onBlurOk(this.state.valor);
  }

  handleFocus = (event) => {
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }


  render() {
    let inputType = 'text';
    if (['renavam', 'data', 'rg', 'telefone', 'celular', 'cep', 'digitos', 'cpf', 'cnpj', 'cpf_cnpj'].indexOf(this.props.tipo) > -1) inputType = 'tel';
    else if (this.props.tipo === 'email') inputType = 'email';

    return <div className={`${this.props.tipo === 'checkbox' ? 'form-check col-12' : (this.props.className || `col-12 py-1`)}`}>
      {this.state.loader ? <LoaderCircular show={this.state.loader} small={true} inputLoader={true}></LoaderCircular> : null}

      {this.props.tipo === 'checkbox' ? <input type="checkbox" className="form-check-input" id={this.props.id} name={this.props.id} value={this.state.valor} checked={this.state.valor} onFocus={this.handleFocus} onChange={this.handleCheckboxChange} /> : null}

      {!this.props.label ? null :
        this.props.obrigatorio && !this.state.valor ?
          <label htmlFor={this.props.id} className={this.props.tipo === 'checkbox' ? 'form-check-label' : ''} style={{ fontSize: this.props.tipo === 'checkbox' ? '14px' : '12px', marginBottom: '2px', color: '#a00' }}>{this.props.label}{this.props.obrigatorio ? ' *' : ''}</label>
          : <label htmlFor={this.props.id} className={this.props.tipo === 'checkbox' ? 'form-check-label' : ''} style={{ fontSize: this.props.tipo === 'checkbox' ? '14px' : '12px', marginBottom: '2px' }}>{this.props.label}{this.props.obrigatorio ? ' *' : ''}</label>}

      {this.props.tipo === 'opcoes' ?
        <select disabled={this.props.desabilitado} className="form-control" style={this.props.style} id={this.props.id} value={this.state.valor} onFocus={this.handleFocus} onChange={this.handleInputChange}>
          <option disabled value="">{this.props.placeholder || 'Selecione'}</option>
          {this.props.opcoes ? this.props.opcoes.map(o => <option key={o.cdOpcao} value={o.cdOpcao}>{o.dsOpcao}</option>) : null}
        </select>

        : this.props.tipo === 'data_vencimento' ?
          <select disabled={this.props.desabilitado} className="form-control" style={this.props.style} id={this.props.id} value={this.state.valor} onFocus={this.handleFocus} onChange={this.handleOpcaoSelecionada}>
            <option disabled value="">{this.props.placeholder || 'Selecione'}</option>
            {DATAS_VENCIMENTO.map(o => <option value={o} key={o}>{o}</option>)}

          </select>

          : this.props.tipo === 'checkbox' ? null
            : this.props.tipo === 'moeda' ?
              <CampoMoeda
                disabled={this.props.desabilitado}
                type={inputType}
                className="form-control"
                style={this.props.style}
                id={this.props.id}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                value={this.state.valor}
                placeholder={this.props.placeholder || ''}
                onChange={(valor) => {
                  this.setState({
                    valor: valor,
                    err: null
                  }, () => {
                    if (this.props.onChange)
                      this.props.onChange(valor);
                  });
                }} />

              : this.props.formatacao === 'mascara' && this.props.mascara ?
                <InputMask disabled={this.props.desabilitado} mask={this.props.mascara} className="form-control" style={this.props.style} id={this.props.id} onFocus={this.handleFocus} onBlur={this.handleBlur} value={this.state.valor} onChange={this.handleInputChange} placeholder={this.props.placeholder || ''}>
                  {(inputProps) => { return <input type={inputType} {...inputProps} disabled={this.props.desabilitado} /> }}
                </InputMask>

                : this.props.tipo === 'hidden' ?

                  <input type={this.props.tipo} className="form-control" style={this.props.style} id={this.props.id} value={this.state.valor} />

                  : <input disabled={this.props.desabilitado} type={inputType} className="form-control" style={this.props.style} id={this.props.id} onFocus={this.handleFocus} onBlur={this.handleBlur} value={this.state.valor} onChange={this.handleInputChange} placeholder={this.props.placeholder || ''} />
      }
      {this.props.texto && this.props.tipo == 'checkbox' ? <p className="text-center ml-2">{this.props.texto}</p> : null}
      {this.state.err ? <small className="text-danger font-weight-bold">{this.state.err}</small> : null}
    </div>
  }
}

export default CampoFormatado;
import React from 'react';
// import Environment from '../../environment';

export default function Paragrafo({ dsTexto = '', dsEstilo = {}, onClick = () => { } }) {
    return (
        <p style={Object.assign({ color: '#666666', fontSize: '19px', lineHeight: '24px' }, dsEstilo)}
            dangerouslySetInnerHTML={{ __html: dsTexto }} onClick={onClick}>
            {/* {icone ? <span><img alt={icone.alt} src={Environment.get('ASSETS') + '/img/' + icone.url} style={icone.style} /></span> : null} */}
        </p>
    )
}
import Axios from 'axios';

import Environment from '../environment';
import analyticsService from './analytics.service';
import authService from './auth.service';
import AuthService from './auth.service';
import coreService from './core.service';
import sessionStoreService from './session-store.service';

class ApiService {

  async get(path) {
    let response;
    let error;
    let tryCount = 0;
    let success = false;

    do {
      try {
        let result = await Axios.get(Environment.get('API_URL') + path, this.getOptions());
        response = result.data;
        success = true;
      } catch (err) {
        analyticsService.sendException(err, false);
        if (err.response && err.response.data && err.response.data.status === 'INVALID_TOKEN')
          try {
            await this.renovarToken();
          } catch (err2) {
            console.error(err2)
            authService.clearAuthData();
            sessionStoreService.clearSession();
            analyticsService.sendException(err2, false);
            window.location.hash = '/inicio'
          }
        else {
          error = err.response ? err.response.data ? err.response.data : err : err;
          tryCount++;
        }
        tryCount++;
      }
    } while (!success && tryCount <= 1)

    if (error)
      throw error;
    return response;
  }

  async post(path, data) {
    let response;
    let error;
    let tryCount = 0;
    let success = false;

    do {
      try {
        let result = await Axios.post(Environment.get('API_URL') + path, data, this.getOptions());
        response = result.data;
        success = true;
      } catch (err) {
        analyticsService.sendException(err, false);
        if (tryCount === 0 && err.response && err.response.data && err.response.data.status === 'INVALID_TOKEN')
          try {
            await this.renovarToken();
          } catch (err2) {
            console.error(err2)
            authService.clearAuthData();
            sessionStoreService.clearSession();
            analyticsService.sendException(err2, false);
            window.location.hash = '/inicio'
          }
        else {
          error = err.response ? err.response.data ? err.response.data : err : err;
          tryCount++;
        }
        tryCount++;
      }
    } while (!success && tryCount <= 1)

    if (error)
      throw error;
    return response;
  }

  async renovarToken() {
    try {
      let url = 'clientes/atualizar-token-acesso';
      let response = await Axios.post(Environment.get('API_URL') + url, {
        tokenAtualizacao: AuthService.getAuthData().tokenAtualizacao
      }, this.getOptions());
      AuthService.setAuthData(response.data);
    } catch (err) {
      throw err.response ? err.response.data ? err.response.data : err : err;
    }
  }

  getOptions() {
    let headers = {};

    if (AuthService.getAuthData())
      headers.Authorization = 'Bearer ' + AuthService.getAuthData().token;

    headers.TrackingId = coreService.getTrackingId();

    return {
      headers
    }
  }
}

export default new ApiService();
import React, { Component, Fragment } from 'react';
import CampoFormatado from '../../components/campo-formatado';
import FormulariosService from '../../services/formularios.service';
import EnderecosService from '../../services/enderecos.service';
import TextUtil from '../../utils/text'

class Campo extends Component {
  constructor(props) {
    super(props);

    let input = props.input || {};
    let state = {
      input,
      timeoutAtualizacao: undefined
    };

    // Garante que exista o input referente ao campo nos dados.
    state.input[props.cdCampo] = input[props.cdCampo] || '';

    this.state = state
  }

  handleCampoAlterado = (valor) => {
    let input = this.state.input;
    input[this.props.cdCampo] = valor;

    this.setState({ input }, async () => {

      // Notifica os componentes sobre a alteração do valor do campo.
      if (this.props.onDadosAlterados)
        await this.props.onDadosAlterados(this.state.input);

      if (this.props.onEvento) {
        // Executa o evento de selecionar uma opção
        if (this.props.cdEvento && this.props.flExecutarEventoAoSelecionar)
          this.props.onEvento(this.props.cdEvento);

        // Executa o evento de atualizar automaticamente após um delay
        else if (this.props.cdEvento && this.props.flAtualizarAutomaticamente) {
          if (this.state.timeoutAtualizacao) clearTimeout(this.state.timeoutAtualizacao);
          let timeout = setTimeout(() => this.props.onEvento(this.props.cdEvento), this.props.vlDelayAtualizacao || 500);
          this.setState({ timeoutAtualizacao: timeout })
        }
      }

    })
  }

  handleOnBlur = async (valor) => {
    // Blur de pesquisa de CEP
    if (this.props.cdTipoCampo == 'cep') {
      this.handleOnBlurCep(valor);
    }

    // Blur para outros campos
    else {
      if (this.props.cdEvento && this.props.flAtualizarAutomaticamente) {
        if (this.state.timeoutAtualizacao) clearTimeout(this.state.timeoutAtualizacao);
        this.props.onEvento(this.props.cdEvento);
      }
    }
  }

  handleOnBlurCep = async (valor) => {
    this.setState({ loader: { message: 'processando...', show: true }, error: null });

    let cdCepLimpo = TextUtil.retornarNumerosSemEspaco(valor);
    if (cdCepLimpo.length !== 8) {
      return this.state.msg = 'Cep incompleto, verificar por favor!';
    }
    let cep = await EnderecosService.recuperarCep(cdCepLimpo);
    let input = this.state.input;

    input.cdUf = cep.cdUf;
    input.dsCidade = cep.dsCidade ? cep.dsCidade.toUpperCase() : (input.dsCidade || '');
    input.dsLogradouro = cep.dsLogradouro ? cep.dsLogradouro.toUpperCase() : (input.dsLogradouro || '');
    input.dsBairro = cep.dsBairro ? cep.dsBairro.toUpperCase() : (input.dsBairro || '');

    this.setState({ input, loader: {} }, async () => {
      // Notifica os componentes sobre a alteração do valor do campo.
      if (this.props.onDadosAlterados)
        await this.props.onDadosAlterados(this.state.input);
    });
  }

  render() {
    return <div className="row justify-content-center m-0" >

      {FormulariosService.avaliarPreRequisitoV2(this.props, this.state.input) ? <CampoFormatado
        id={this.props.cdCampo}
        className="col-12 p-0"
        valor={this.state.input[this.props.cdCampo]}
        label={this.props.dsTexto}
        placeholder={this.props.dsPlaceholder}
        formatacao={this.props.dsFormatacao}
        mascara={this.props.dsMascara}
        tamanho={this.props.vlTamanho}
        tipo={this.props.cdTipoCampo}
        opcoes={this.props.opcoes}
        obrigatorio={this.props.flObrigatorio}
        desabilitado={!this.props.flHabilitado}
        onChange={this.handleCampoAlterado}
        onBlurOk={this.handleOnBlur}
        onFocus={this.props.flSelecionarConteudoAoFocar ? (event) => event.target.select() : undefined}
        style={Object.assign({ borderRadius: '10px', color: '#666666', height: '50px', border: '1px solid #666666', fontSize: '17px' }, this.props.dsEstilo || {})}
      /> : null}
    </div>
  }
}

export default Campo;
import React, { Component } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Environment from '../../environment';

class BtnActions extends Component {

    handleEvento = (b) => {
        if (b.dsLink) {
            window.open(b.dsLink, '_blank');
        } else if (b.cdEvento && this.props.onEvento) {
            this.props.onEvento(b.cdEvento);
        }
    }

    render() {
        return <Fragment>
            {this.props.tipo == 'LISTA' ? <div className='pt-2' style={{ display: 'flex', margin: '0 -5px', flexDirection: 'column' }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn btn-' + b.tipo}
                        style={Object.assign({ borderRadius: '50px', height: '45px', flexGrow: 1, margin: 5 }, b.style || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.label}
                    </button>
                })}
            </div> : null}

            {this.props.tipo == 'FAB' ? <div className='row justify-content-end pt-2' >
                {this.props.botoes.map((b, index) => {
                    return <h6 key={index} className='m-0' onClick={(e) => this.handleEvento(b)} style={
                        Object.assign({
                            background: '#001C55',
                            color: '#fff',
                            borderRadius: '50%',
                            height: '50px',
                            width: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, b.styleH6 || {})
                    }>
                        {b.label ? <span>{b.label}</span> : null}
                        <img alt="Daycoval" src={Environment.get('ASSETS') + '/img/' + b.img} style={Object.assign({ height: '30px' }, b.style || {})} ></img>
                    </h6>
                })}
            </div> : null}

            {this.props.tipo == 'LADO' ? <div className='pt-2' style={{ display: 'flex', margin: '0 -5px' }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn btn-' + b.tipo}
                        style={Object.assign({ borderRadius: '50px', height: '45px', flexGrow: 1, margin: 5 }, b.style || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.label}
                    </button>
                })}
            </div> : null}

            {this.props.tipo == 'CANTOS' ? <div className='pt-2' style={{
                display: 'flex', margin: '0 -5px', 
                justifyContent: 'space-between',
                flexDirection: 'row-reverse'
            }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn btn-outline-' + (b.tipo || 'light')}
                        style={Object.assign({ borderRadius: '50px', border: 'none', margin: 5 }, b.style || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.label}
                    </button>
                })}
            </div> : null}
        </Fragment>
    }
}

export default BtnActions;
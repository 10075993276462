import React, { Component, Fragment } from 'react';
import Environment from '../../environment';

class Cabecalho extends Component {

    render() {
        if (!this.props.tela) return;
        if (!this.props.tela.cabecalho) return;

        let tela = this.props.tela;
        let cabecalho = this.props.tela.cabecalho;
        return <Fragment>

            <div className='navbar-cabecalho' style={{
                paddingTop: tela.cdTipoTela == 'CARD_MEDIO' ? '15px' : tela.cdTipoTela == 'CARD_COMPLETO' ? '0' : '0',
                paddingRight: tela.cdTipoTela == 'CARD_MEDIO' ? '15px' : tela.cdTipoTela == 'CARD_COMPLETO' ? '10px' : '0',
                paddingLeft: tela.cdTipoTela == 'CARD_MEDIO' ? '15px' : tela.cdTipoTela == 'CARD_COMPLETO' ? '10px' : '0',
                height: tela.cdTipoTela == 'CARD_MEDIO' ? '40%' : tela.cdTipoTela == 'CARD_COMPLETO' ? '60px' : '0',
                minHeight: tela.cdTipoTela == 'CARD_MEDIO' ? '200px' : tela.cdTipoTela == 'CARD_COMPLETO' ? '60px' : '0',
                display: tela.cdTipoTela == 'APRESENTACAO' ? 'none' : 'flex',
                flexDirection: tela.cdTipoTela == 'CARD_MEDIO' ? 'column' : tela.cdTipoTela == 'CARD_COMPLETO' ? 'row' : 'row'
            }}>

                {/* CABEÇALHO GRANDE, COLOCANDO OS BOTÕES EM UMA BARRA SUPERIOR, E AS IMAGENS CENTRALIZADAS NO ESPAÇO DISPONÍVEL */}
                {tela.cdTipoTela == 'CARD_MEDIO' ? <Fragment>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div className='itens start' style={{ alignSelf: 'flex-start' }}>
                            {(cabecalho.botoesEsquerda || []).map((bte, index) => {
                                return <a key={index} href={bte.redirecionar}>
                                    <img className={bte.className} key={bte.alt} alt={bte.alt} src={Environment.get('ASSETS') + `/img/${bte.img}`} onClick={(e) => this.props.onEvento(bte.cdEvento)}></img>
                                </a>
                            })}
                        </div>
                        <div className='itens end' style={{ alignSelf: 'flex-end' }}>
                            {(cabecalho.botoesDireita || []).map((btd, index) => {
                                return <a key={index} href={btd.redirecionar}>
                                    <img className={btd.className} key={btd.alt} alt={btd.alt} src={Environment.get('ASSETS') + `/img/${btd.img}`} onClick={(e) => this.props.onEvento(btd.cdEvento)}></img>
                                </a>
                            })}
                        </div>
                    </div>

                    <div className='itens center' style={{ alignSelf: 'center', display: 'flex', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                        {(cabecalho.imagens || []).map((i, index) => {
                            return <img key={index} className={i.className} style={i.style} alt={i.alt} src={Environment.get('ASSETS') + `/img/${i.img}`}></img>
                        })}
                    </div>
                </Fragment> : null}

                {/* CABEÇALHO PEQUENO IMITANDO UMA BARRA DE NAVEGAÇÃO SUPERIOR, MOSTRANDO OS BOTÕES NAS LATERAIS E AS IMAGENS DENTRO DA BARRA */}
                {tela.cdTipoTela == 'CARD_COMPLETO' ? <Fragment>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <div className='itens start' style={{ alignSelf: 'flex-start', flexGrow: 'unset' }}>
                            {(cabecalho.botoesEsquerda || []).map((bte, index) => {
                                return <img key={index} className={bte.className} alt={bte.alt} src={Environment.get('ASSETS') + `/img/${bte.img}`} onClick={(e) => this.props.onEvento(bte.cdEvento)}></img>
                            })}
                        </div>

                        <div className='itens center' style={{ alignSelf: 'flex-start', textAlign: 'left', paddingLeft: '20px' }}>
                            {(cabecalho.imagens || []).map((i, index) => {
                                return <img key={index} className={i.className} style={i.style} alt={i.alt} src={Environment.get('ASSETS') + `/img/${i.img}`}></img>
                            })}
                        </div>

                        <div className='itens end' style={{ alignSelf: 'flex-start', flexGrow: 'unset' }}>
                            {(cabecalho.botoesDireita || []).map((btd, index) => {
                                return <img key={index} className={btd.className} alt={btd.alt} src={Environment.get('ASSETS') + `/img/${btd.img}`} onClick={(e) => this.props.onEvento(btd.cdEvento)}></img>
                            })}
                        </div>
                    </div>
                </Fragment> : null}
            </div>
        </Fragment>
    }
}
export default Cabecalho;
import analyticsService from "./analytics.service";
import SessionStoreService from './session-store.service';
import { v4 as uuidv4 } from 'uuid';

class CoreService {

  canInstallApp() {
    return window.installAppPrompt && window.installAppPrompt.prompt
  }

  async installApp() {
    if (window.installAppPrompt) {
      try {
        await window.installAppPrompt.prompt();
        window.installAppPrompt.userChoice
          .then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('Aplcativo será instalado.');
            } else {
              console.log('Aplicativo não será instalado.');
            }
            window.installAppPrompt = null;
          });
      } catch (err) {
        console.error(err);
        analyticsService.sendException(err, false);
      }
    }
  }

  getTrackingId() {
    let trackingid = SessionStoreService.getGenericData('trackingid');
    if (!trackingid){
      trackingid = uuidv4().toString().toUpperCase().replace(new RegExp('-', 'gi'), '');
      SessionStoreService.saveGenericData('trackingid', trackingid)
    }
    return trackingid;
  }
}

export default new CoreService();
import React, { Component, Fragment } from 'react';
import Titulo from '../../components/jornadas/titulos';
import Componentes from './componente';
import CamposInput from '../campo-input';
import BtnActions from '../../components/jornadas/btn-actions'
import LoaderCircular from '../loader/loader-circular';
import Msgs from '../msgs';

class CardJornada extends Component {

    handleDadosAlterados = async (dados) => {
        if (this.props.onDadosAlterados)
            await this.props.onDadosAlterados(dados);
    }

    render() {
        let tela = this.props.tela;

        return <Fragment>
            {tela ?
                <div className={`card-jornada ${tela.cdTipoTela == 'CARD_MEDIO' ? 'pequeno' : 'grande'}`}
                    style={{
                        height: `${tela.cdTipoTela == 'CARD_MEDIO' ? '60%'
                            : tela.cdTipoTela == 'CARD_COMPLETO' ? 'calc(100% - 60px)'
                                : tela.cdTipoTela == 'APRESENTACAO' ? '100%' : '100%'}`,
                        minHeight: `${tela.cdTipoTela == 'CARD_MEDIO' ? '300px'
                            : tela.cdTipoTela == 'CARD_COMPLETO' ? '300px'
                                : tela.cdTipoTela == 'APRESENTACAO' ? '400px' : '400px'}`,
                        background: tela.cdTipoTela == 'APRESENTACAO' ? 'transparent' : '#fff',
                        boxShadow: tela.cdTipoTela == 'APRESENTACAO' ? 'none' : '1px 1px 6px #32326433',
                        borderRadius: tela.cdTipoTela == 'APRESENTACAO' ? '0' : '30px 30px 0 0',
                        scrollBehavior: 'auto',
                        overflowY: 'auto',
                        ...tela.dsEstiloCard
                    }}>

                    {/* LOADER */}
                    {this.props.loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <LoaderCircular show={true}></LoaderCircular>
                    </div> : null}

                    {/* CONTEÚDO */}
                    {this.props.loading ? null : <Fragment>
                        {this.props.error ? <Msgs error={this.props.error} /> : null}
                        <div className='conteudo'>
                            <Componentes
                                componentes={tela.componentes}
                                input={this.props.input}
                                onEvento={this.props.onEvento}
                                onDadosAlterados={this.handleDadosAlterados} />
                        </div>
                    </Fragment>}
                </div> : null}
        </Fragment>
    }

}
export default CardJornada;
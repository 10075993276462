import React, { Fragment } from 'react';

import Titulo from './titulos';
import Paragrafo from './paragrafo';
import Espacador from './espacador';
import Imagem from './imagem';
import Botoes from './botoes';
import Progresso from './progresso';
import Campo from './campo';

import ParagrafoEditavel from './paragrafo-editavel';
import ListaArquivos from './lista-arquivos';
import AdicionarArquivo from './adicionar-arquivo';
import ListaSimples from './lista-simples';
import Camera from './modal-camera'
import Localizacao from './localizacao'

export default function Componentes({ componentes, input, onDadosAlterados = async () => { }, onEvento = async () => { } }) {
  return <Fragment>
    {componentes.map((componente, index) => {
      let tipo = componente.cdTipo;

      if (tipo == 'TITULO') return <Titulo key={index} {...componente} />
      else if (tipo == 'PARAGRAFO') return <Paragrafo key={index} {...componente} /> //TODO - ICONES EM PARÁGRAFOS
      else if (tipo == 'ESPACADOR') return <Espacador key={index} {...componente} />
      else if (tipo == 'IMAGEM') return <Imagem key={index} {...componente} /> //TODO - EVENTO DE CLIQUE NA IMAGEM
      else if (tipo == 'BOTOES') return <Botoes key={index} {...componente} onEvento={onEvento} />
      else if (tipo == 'PROGRESSO') return <Progresso key={index} {...componente} />
      else if (tipo == 'CAMPO') return <Campo key={index} {...componente} input={input} onDadosAlterados={onDadosAlterados} onEvento={onEvento} />
      else if (tipo == 'ADICIONAR_ARQUIVO') return <AdicionarArquivo key={index} {...componente} onEvento={onEvento} />
      else if (tipo == 'LISTA_ARQUIVOS') return <ListaArquivos key={index} {...componente} onEvento={onEvento} />

      else if (tipo == 'PARAGRAFO_EDITAVEL') return <ParagrafoEditavel key={index} componente={componente} input={input} onDadosAlterados={onDadosAlterados} onEvento={onEvento} />
      else if (tipo == 'LISTA_SIMPLES') return <ListaSimples key={index} componente={componente} onEvento={onEvento} />
      else if (tipo == 'CAMERA') return <Camera key={index} componente={componente} onEvento={onEvento} input={input} />
      else if (tipo == 'LOCALIZACAO') return <Localizacao key={index} componente={componente} onEvento={onEvento} input={input} />
      else return <Fragment key={index}></Fragment>;
    })}
  </Fragment >
}
import React, { Component } from 'react';


class Geolocalizacao extends Component {

  componentDidMount = async () => {
    this.checkPermission()

  }

  checkPermission = async () => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'prompt') {
          this.getCurrentPosition()

        } else if (result.state === 'granted') {
          if (this.props.input.flPedirPermissaoLocalizacao)
            this.getCurrentPosition()

        } else if (result.state === 'denid') {
          if (this.props.input.flPedirPermissaoLocalizacao)
            this.getCurrentPosition()
        }
      })
    }
  }


  getCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition, this.showError);
    }

  }
  showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.")
        this.props.onEvento(this.props.componente.props.cdEventoPermissaoLocalizacaoInvalida, { error: error.PERMISSION_DENIED });
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")
        this.props.onEvento(this.props.componente.props.cdEventoPermissaoLocalizacaoInvalida);
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out")
        this.props.onEvento(this.props.componente.props.cdEventoPermissaoLocalizacaoInvalida);
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
        break;

      default:
        break;
    }
  }


  showPosition = async (position) => {
    let vlLatitude = position.coords.latitude
    let vlLongitude = position.coords.longitude;
    this.props.onEvento(this.props.componente.props.cdEventoPermissaoLocalizacaoValida, { vlLatitude, vlLongitude });
  }


  handleVoltar = () => {
    if (this.props.onEvento)
      this.props.onEvento('VOLTAR');
  }


  render() {
    return <div>
      <p>A autorização da localização é importante para fotografar a fachada sua loja, os dados serão usados apenas como comprovante de endereço.</p>
    </div>
  }
}

export default Geolocalizacao;
import React, { Fragment } from 'react';


export default function Progresso({ cdTipoProgresso = '', qtIndicadores = 0, vlIndicadorAtivo = 0, qtIndicadoresCompletos = 0, dsAlign = '' }) {
    return (
        <div className="container mb-2" style={Object.assign(
            {
                justifyContent: dsAlign == 'CENTER' ? 'center'
                    : dsAlign == 'RIGHT' ? 'right'
                        : dsAlign == 'LEFT' ? 'left'
                            : 'left',

                alignItems: dsAlign == 'CENTER' ? 'center'
                    : dsAlign == 'RIGHT' ? 'right'
                        : dsAlign == 'LEFT' ? 'left'
                            : 'center',
            }
        )}>
            {qtIndicadores > 0 ? <Fragment>
                {[...Array(qtIndicadores)].map((n, i) => {
                    return <div key={i} className={`
                        progressBar 
                        ${cdTipoProgresso == 'CIRCULOS' ? 'circle' : cdTipoProgresso == 'CHECK' ? 'steps' : ''} 
                        ${qtIndicadoresCompletos > i ? 'filled' : ''}
                        ${vlIndicadorAtivo == i + 1 ? 'active' : ''}
                        `}></div>
                })}
            </Fragment> : null}
        </div>
    )
}
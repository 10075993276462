import React, { Component, Fragment } from 'react';
import LoaderCircular from '../../components/loader/loader-circular';
import BackgroundJornada from '../../components/jornadas/background-jornada';
import Cabecalho from '../../components/jornadas/cabecalho';
import CardJornada from '../../components/jornadas/card-jornada';
import JornadaService from '../../services/jornada.service';
import ParamsUtil from "../../utils/params";
import AuthService from '../../services/auth.service';
import SessionStoreService from '../../services/session-store.service';
import CoreService from '../../services/core.service';
import analyticsService from '../../services/analytics.service';

class DetalheJornada extends Component {

  constructor(props) {
    super(props);

    let params = ParamsUtil.getUrlParams(window.location.hash);

    this.state = {
      cdJornada: this.props.match.params.cdJornada,
      params,
      input: params || {},
      telaAtual: undefined,
      loading: false,
      error: undefined,
      msg: undefined,
      permiteSalvarApp: undefined,
    };
  }

  async componentDidMount() {
    await this.loadData();
    if (this.state.params.error) this.setState({ error: this.state.params.error });
    if (this.state.params.msg) this.setState({ msg: this.state.params.msg });

    setTimeout(() => {
      this.setState({ permiteSalvarApp: CoreService.canInstallApp() })
    }, 2000);
  }

  rastrearTela = () => {
    let rastreamento = [
      this.state.rastreamento.cdJornada,
      this.state.rastreamento.cdEtapa,
      this.state.rastreamento.cdMomento,
      this.state.rastreamento.cdTela
    ];

    console.log(rastreamento);

    analyticsService.sendPageView(
      `jornadas/${rastreamento.filter(r => !!r).join('/')}`,
      `Jornada ${rastreamento.filter(r => !!r).join(' ')}`
    );
  }

  rastrearEvento = (cdEvento) => {
    let rastreamento = [
      this.state.rastreamento.cdJornada,
      this.state.rastreamento.cdEtapa,
      this.state.rastreamento.cdMomento,
      this.state.rastreamento.cdTela,
      cdEvento
    ];

    console.log(rastreamento);

    analyticsService.sendEvent(
      `jornadas/${rastreamento.filter(r => !!r).join('/')}`,
      `jornadas/${rastreamento.filter(r => !!r).join('/')}`
    );
  }

  loadData = async () => {
    this.setState({ loading: true, error: undefined, msg: undefined });
    try {
      let telaAtual = await JornadaService.recuperarJornada(this.state.cdJornada, this.state.input);

      // Tratamento para redirecionar para outra URL
      // Interrompe o fluxo quando houver
      if (telaAtual.redirecionarUrl) {
        return window.location = telaAtual.redirecionarUrl;
      }
      if (telaAtual.redirecionarHash) {
        window.location.hash = telaAtual.redirecionarHash;
        return window.location.reload();
      }
      if (telaAtual.abrirUrl) {
        window.open(telaAtual.abrirUrl, '_blank');
      }

      // Caso não tenha redirecionamento e não tenha tela atual, temos um erro.
      if (!telaAtual.cdTela) return this.setState({ loading: false, error: 'Ocorreu um problema ao recuperar a jornada', msg: undefined });

      // Tratamento para mensagens de erro.
      if (telaAtual.error) {
        this.setState({ error: telaAtual.error });
      }

      let input = this.state.input;
      input = Object.assign(input, telaAtual.input || {}, telaAtual.dados || {});

      // Campos para remover so state (esquecer valores)
      if (telaAtual.removerInput) {
        for (let campo of telaAtual.removerInput) {
          if (input[campo]) delete input[campo];
        }
      }

      this.setState({
        telaAtual,
        input,
        rastreamento: {
          cdJornada: telaAtual.cdJornada,
          cdEtapa: telaAtual.cdEtapa,
          cdMomento: telaAtual.cdMomento,
          cdTela: telaAtual.cdTela,
        }
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar as telas. Tente novamente ou contate nosso suporte.' });

    }

    // Delay para sair loader proposital.
    // Melhora a experiência de uso.
    setTimeout(() => {
      this.setState({ loading: false });
      this.rastrearTela();
    }, 100);
  }

  handleDadosAlterados = async (dados) => {
    return new Promise(resolve => {
      let dadosInput = Object.assign(this.state.input, dados);
      this.setState({ input: dadosInput }, resolve);
    })
  }

  handleEvento = async (cdEvento, inputEvento) => {
    if (!cdEvento) return;
    this.setState({ loading: true, error: undefined, msg: undefined });

    if (cdEvento == 'INSTALAR_APP') await this.instalarApp();

    let result;

    try {
      let dados = {
        cdJornada: this.state.telaAtual.cdJornada,
        cdTela: this.state.telaAtual.cdTela,
        cdTelaAnterior: this.state.cdTelaAnterior,
        cdEvento,
        input: this.state.input,
      }

      if (inputEvento) dados.input = Object.assign(dados.input, inputEvento);

      result = await JornadaService.processarEvento(dados);

      this.rastrearEvento(cdEvento);

      // Tratamento para redirecionar para outra URL
      // Interrompe o fluxo quando houver
      if (result.redirecionarUrl) {
        return window.location = result.redirecionarUrl;
      }
      if (result.redirecionarHash) {
        window.location.hash = result.redirecionarHash;
        return window.location.reload();
      }
      if (result.abrirUrl) {
        window.open(result.abrirUrl, '_blank');
      }

      // Tratamento para mensagens de erro.
      if (result.error) {
        this.setState({ error: result.error });
      }

      // Tratamento para quando houver dados de autenticação.
      if (result.auth) {
        AuthService.clearAuthData();
        AuthService.setAuthData(result.auth);
        if (result.auth.cliente)
          SessionStoreService.saveGenericData('cliente', result.auth.cliente);
      }

      let input = this.state.input;
      input = Object.assign(input, result.input || {}, result.dados || {});

      // Campos para remover so state (esquecer valores)
      if (result.removerInput) {
        for (let campo of result.removerInput) {
          if (input[campo]) delete input[campo];
        }
      }

      let telaAtual = this.state.telaAtual;
      if (result.proximaTela && result.proximaTela.cdTela) {
        telaAtual = result.proximaTela;
      }

      this.setState({
        cdTelaAnterior: result.cdTelaAnterior || this.state.cdTelaAnterior,
        telaAtual,
        input,
        rastreamento: {
          cdJornada: telaAtual.cdJornada,
          cdEtapa: telaAtual.cdEtapa,
          cdMomento: telaAtual.cdMomento,
          cdTela: telaAtual.cdTela,
        }
      });
    } catch (err) {
      console.error(err);
      this.setState({ error: err.msg || 'Falha ao recuperar as telas. Tente novamente ou contate nosso suporte.' });
    }

    // Delay para sair loader proposital.
    // Melhora a experiência de uso.
    setTimeout(() => {
      this.setState({ loading: false });
      this.rastrearTela();
    }, 600);

    return result ? result.input : undefined;
  }

  instalarApp = async () => {
    if (this.state.permiteSalvarApp || CoreService.canInstallApp())
      await CoreService.installApp();
  }

  render() {
    if (!this.state.telaAtual)
      return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><LoaderCircular show={true}></LoaderCircular></div>

    let telaAtual = this.state.telaAtual && this.state.telaAtual.cdTela ? this.state.telaAtual : undefined;

    return <Fragment>
      {telaAtual ?
        <BackgroundJornada tela={telaAtual}>

          <Cabecalho tela={telaAtual} onEvento={this.handleEvento} />

          <CardJornada
            tela={telaAtual}
            input={this.state.input}
            loading={this.state.loading}
            error={this.state.error}
            onDadosAlterados={this.handleDadosAlterados}
            onEvento={this.handleEvento} />

        </BackgroundJornada> : null}
    </Fragment>
  }
}

export default DetalheJornada;
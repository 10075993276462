import React, { Component } from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import Environment from '../../environment';

class Botoes extends Component {

    handleEvento = (b) => {
        if (b.dsLink) {
            window.open(b.dsLink, '_blank');
        } else if (b.cdEvento && this.props.onEvento) {
            this.props.onEvento(b.cdEvento);
        }
    }

    render() {
        return <Fragment>
            {this.props.cdTipoBotao == 'LISTA' ? <div className='pt-2' style={{ display: 'flex', margin: '0', flexDirection: 'column' }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn ' + (b.dsClasseCss || 'btn-primary')}
                        style={Object.assign({ borderRadius: '50px', height: '45px', flexGrow: 1, margin: 5 }, b.dsEstilo || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.dsTexto}
                    </button>
                })}
            </div> : null}

            {this.props.cdTipoBotao == 'FAB' ? <div className='row justify-content-end pt-2' >
                {this.props.botoes.map((b, index) => {
                    return <h6 key={index} className='m-0' onClick={(e) => this.handleEvento(b)} style={
                        Object.assign({
                            background: '#001C55',
                            color: '#fff',
                            borderRadius: '50%',
                            height: '50px',
                            width: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }, b.dsEstiloH6 || {})
                    }>
                        {b.dsTexto ? <span>{b.dsTexto}</span> : null}
                        <img alt="Daycoval" src={Environment.get('ASSETS') + '/img/' + b.img} style={Object.assign({ height: '30px' }, b.dsEstilo || {})} ></img>
                    </h6>
                })}
            </div> : null}

            {this.props.cdTipoBotao == 'LADO' ? <div className='pt-2' style={{ display: 'flex', margin: '0' }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn ' + (b.dsClasseCss || 'btn-primary')}
                        style={Object.assign({ borderRadius: '50px', height: '45px', flexGrow: 1, margin: 5 }, b.dsEstilo || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.dsTexto}
                    </button>
                })}
            </div> : null}

            {this.props.cdTipoBotao == 'CANTOS' ? <div className='pt-2' style={{
                display: 'flex', margin: '0',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse'
            }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn ' + (b.dsClasseCss || 'btn-outline-light')}
                        style={Object.assign({ borderRadius: '50px', border: 'none', margin: 5 }, b.dsEstilo || {})}
                        onClick={e => this.handleEvento(b)}>
                        {b.dsTexto}
                    </button>
                })}
            </div> : null}

            {this.props.cdTipoBotao == 'OPCOES' ? <div className='pt-2' style={{
                display: 'flex',
                margin: '0',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                {this.props.botoes.map((b, index) => {
                    return <button
                        key={index}
                        type="button"
                        className={'btn ' + (b.dsClasseCss || 'btn-primary')}
                        style={Object.assign({ borderRadius: '10px', height: '45px', flexGrow: 1, margin: 5, minWidth: '40%' }, b.dsEstilo || {})}
                        onClick={e => this.handleEvento(b)}
                        dangerouslySetInnerHTML={{ __html: b.dsTexto }}>
                    </button>
                })}
            </div> : null}
        </Fragment>
    }
}

export default Botoes;
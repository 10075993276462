import Utils from '../utils/text';
import moment from 'moment';
class FormulariosService {
  /**
   * #deprecated
   * @param {*} campo 
   * @param {*} valores 
   * @returns 
   */
  avaliarPreRequisito = (campo = {}, valores = {}) => {
    if (!campo.preRequisitos || campo.preRequisitos.length === 0) return true;

    let preRequisitoAtendido = true;
    for (let preRequisito of campo.preRequisitos) {
      if (!preRequisito.idCampo) continue;

      else if (!preRequisito.vlCampo || preRequisito.vlCampo === true) {
        if (valores[preRequisito.idCampo]) continue;
      }

      else if (preRequisito.vlCampo.indexOf(valores[preRequisito.idCampo]) > -1) continue;

      preRequisitoAtendido = false;
    }

    return preRequisitoAtendido;
  }

  avaliarPreRequisitoV2 = (campo = {}, input = {}) => {
    if (!campo.preRequisitos || campo.preRequisitos.length === 0) return true;

    let preRequisitoAtendido = true;

    // Para cada pré-requisito do campo
    for (let preRequisito of campo.preRequisitos) {

      // Se não estiver definido o código do campo pré-requisito, consideramos como atendido.
      if (!preRequisito.cdCampo) continue;

      // Caso não tenha nenhum valor específico e qualquer coisa esteja preenchida no campo, consideramos como atendido.
      else if (!preRequisito.vlCampo || preRequisito.vlCampo === true) {
        if (input[preRequisito.cdCampo]) continue;
      }

      // Caso deva ser algum valor específico, validamos se algum destes valores está preenchido para considerar atendido.
      else if (preRequisito.vlCampo.indexOf(input[preRequisito.cdCampo]) > -1) continue;

      // Caso nenhuma das condições sejam atendidas, consideramos como o pré-requisito não atendido e encerramos a verificação.
      preRequisitoAtendido = false;
      break;
    }

    return preRequisitoAtendido;
  }

  avaliarConteudoCampo(valor, tipo, obrigatorio, valorMinimo, valorMaximo, tamanhoMinimo) {
    // Caso o campo não seja obrigatório e não tenha valor, ele é considerado válido.
    if (!obrigatorio && (!valor || String(valor).length < 1))
      return { isValid: true };

    // Se o campo for obrigatório e não tiver valor, é considerado inválido.
    if (obrigatorio && (!valor || String(valor).length < 1))
      return { err: 'Você deve preencher corretamente esta informação.', isValid: false };

    // Se o campo tiver algum valor, mesmo que não seja obrigatório, ele é validado em todas as regras.

    if (tamanhoMinimo && !isNaN(tamanhoMinimo) && tamanhoMinimo > String(valor).length)
      return { err: `Este campo precisa ter ao menos ${tamanhoMinimo} caracteres.`, isValid: false };

    if (valorMinimo && parseInt(String(valor).replace(/[^\d]+/g, "")) < valorMinimo)
      return { err: `Mínimo permitido é ${valorMinimo}.`, isValid: false };

    if (valorMaximo && parseInt(String(valor).replace(/[^\d]+/g, "")) > valorMaximo)
      return { err: `Máximo permitido é ${valorMaximo}.`, isValid: false };

    switch (tipo) {
      case 'cpf':
        if (!Utils.validarCPF(valor)) {
          return { err: 'O CPF digitado não é válido.', isValid: false };
        }
        break;

      case 'cnpj':
        if (!Utils.validarCNPJ(valor)) {
          return { err: 'O CNPJ digitado não é válido.', isValid: false };
        }
        break;

      case 'cpf_cnpj':
        if (!Utils.validarCPF(valor) && !Utils.validarCNPJ(valor)) {
          return { err: 'O documento digitado não é válido.', isValid: false };
        }
        break;

      case 'chassi':
        if (!Utils.validarChassi(valor)) {
          return { err: 'O CHASSI digitado não é válido.', isValid: false };
        }
        break;

      case 'renavam':
        if (!Utils.validarRenavam(valor)) {
          return { err: 'O RENAVAM digitado não é válido.', isValid: false };
        }
        break;

      case 'email':
        if (!Utils.validarEmail(valor)) {
          return { err: 'O e-mail digitado não é válido.', isValid: false };
        }
        break;

      case 'celular':
        if (!Utils.validarCelular(valor)) {
          return { err: 'O telefone celular digitado não é válido.', isValid: false };
        }
        break;

      case 'data':
        let tmpData = moment.utc(valor, 'DD/MM/YYYY', true);
        if (!tmpData.isValid()) {
          return { err: 'A data digitada não é válida.', isValid: false };
        }
        break;

      default:
        break;
    }

    return { isValid: true };
  }
}


export default new FormulariosService();
